.rodape {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8rem;
}

@media (min-width: 700px) {
    .rodape {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

.nava {
    text-decoration: none;
    color: #ffffff;
    font-size: .8rem;
}

.navMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.navMenuItem {
    margin-right: 2em;
}

.navMenuItem2 {
    margin-right: 1em;
}

.navlink {
    color: #ffffff;
    font-size: .8rem;
}

.navicon {
    width: 10em;
}

.navcop {
    font-size: 0.6em;
    width: 40em;
    text-align: center;
    padding-bottom: 4rem;
}

.whatsapp-btn {
    display: inline-flex;
    align-items: center;
    background-color: #25D366;
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 20px;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .whatsapp-btn:hover {
    background-color: #1EBE5F;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .whatsapp-btn:active {
    background-color: #169E4F;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  }
  
  .whatsapp-icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
  