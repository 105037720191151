.xtudo {
    margin-left: 2rem;
    margin-right: 2rem;
}



.Titulo1 {
    color: #F2F2F2;
}

.margem {

    padding: 0rem 15rem 0rem 15rem;
}

.btface {
    margin: 1em auto;
    width: 13em;
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    height: 3rem;
    padding: .4em 1.8em;
    font-size: 1.2em;
    border-radius: 8px;
    color: #F2F2F2;
    background: #0D0D0D;
    border-color: #F2F2F2;
}

.btface:hover {
    color: #F2F2F2;
    background: #4D51D6;
    border-color: #4D51D6;
}