.sobre {
    margin-top: 2rem;
    line-height: 1.4;
    letter-spacing: 2px;
    font-weight: 200;
    color: white;
}

.tverde {
    color: #12C15D;
}

.tvermelho {
    color: #C12A12;
}

.tazul {
    color: #4D51D6;
}

.tamarelo {
    color: #FCDB35;
}

.xtudol {
    margin-left: 2rem;
    margin-right: 2rem;
}

@media (min-width: 980px) {
    .xtudol {
        margin-left: 4rem;
        margin-right: 4rem;
    }
}

.whatsapp-btn {
    display: inline-flex;
    align-items: center;
    background-color: #25D366;
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 20px;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .whatsapp-btn:hover {
    background-color: #1EBE5F;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .whatsapp-btn:active {
    background-color: #169E4F;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  }
  
  .whatsapp-icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }

  