.message {

  padding: .4em 1em .4em 1em;


}

.avatar {

  margin-right: .5em;
  border-radius: 40px;
  object-fit: cover;

}



.cilinha1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.citexto {
  color: #FFFFFF;
}