 .liveConteudo {
   display: flex;
   justify-content: center;
   flex-direction: column;

 }

 .liveLocalizar {
   display: flex;
   justify-content: center;
   flex-direction: row;
   margin-bottom: 2rem;
   align-items: center;
 

 }

 .liveinput {
   width: 60%;
   box-sizing: border-box;
   border: 4px solid #FFFFFF;
   border-radius: 8px;
   font-size: 1em;
   background-color: #0A0A1C;
   caret-color: #FFFFFF;
   padding: 0.4rem 1rem 0.4rem 1rem;
   color: #FFFFFF;
 }

 .liveinput::placeholder {
   color: #C6B5F8;

 }

 .lbt {
   margin-left: 1rem;
   cursor: pointer;
 }

 .lbt2 {
   border: 2px solid #ffffff;
   border-radius: 3rem;
   width: 3rem;
   height: 3rem;
   cursor: pointer;
   margin: 0 auto;
   padding: .4rem;
 }

 .lbt3 {
   border: 2px solid #ffffff;
   border-radius: 4rem;
   width: 4rem;
   height: 4rem;
   cursor: pointer;
   margin: 0 auto;
   padding: .4rem;
 }

 .lbt4 {

   width: 4rem;
   height: 4rem;
   margin: 0 auto;
   padding: .4rem;
 }


 .liveul {
   display: grid;
   grid-template-columns: 1fr;
   margin: 0 auto;
   grid-gap: 2rem;
 }


 .kr_conteiner {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   margin-bottom: 2rem;
 }


 .kr_carousel {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;

   width: 16rem;
   height: 9rem;
 }

 .kr_slide {
   border-radius: 0.5rem;
   /* box-shadow: 0px 0px 7px #666; */
   width: 100%;
   height: 100%;

 }

 .kr_slide-hidden {
   display: none;
 }

 .kr_arrow {
   position: absolute;
   filter: drop-shadow(0px 0px 5px #555);
   width: 2rem;
   height: 2rem;
   color: white;
 }

 .kr_arrow:hover {
   cursor: pointer;
 }

 .kr_arrow-left {
   left: 1rem;
 }

 .kr_arrow-right {
   right: 1rem;
 }

 .kr_indicators {
   display: flex;
   position: absolute;
   bottom: 1rem;
 }

 .kr_indicator {
   background-color: white;
   height: 0.5rem;
   width: 0.5rem;
   border-radius: 100%;
   border: none;
   outline: none;
   box-shadow: 0px 0px 5px #555;
   margin: 0 0.2rem;
   cursor: pointer;
 }

 .kr_indicator-inactive {
   background-color: grey;
 }



 @media (min-width: 700px) {
   .liveul {
     grid-template-columns: 1fr 1fr;
   }

   .kr_carousel {

     width: 16rem;
     height: 9rem;
   }
 }

 @media (min-width: 980px) {
   .liveul {
     grid-template-columns: 1fr 1fr;
   }

   .kr_carousel {

     width: 32rem;
     height: 18rem;
   }
 }

 @media (min-width: 1400px) {
   .liveul {
     grid-template-columns: 1fr 1fr 1fr;
   }

   .kr_carousel {

     width: 32rem;
     height: 18rem;
   }
 }